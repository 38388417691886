import React from "react";
import { Link } from "gatsby";
import Layout from "../../Layout";
import PageTitle from "../../../components/PageTitle";
import Markdown from "../../../components/Markdown";

const ArticlesShow = ({ articleData }) => {
  const renderArticle = () => {
    if (articleData) {
      const {
        strapiId,
        title,
        content,
        published_at: publishedAt,
        original_published_at: originalPublishedAt,
      } = articleData;
      return (
        <div>
          <div id={`article-header-${strapiId}`} className="text-center">
            <PageTitle>{title}</PageTitle>
            <div className="text-secondary font-medium italic">
              {originalPublishedAt || publishedAt}
            </div>
          </div>
          <div id={`article-content-${strapiId}`} className="my-10">
            <Markdown>{content}</Markdown>
          </div>
          <div id={`article-header-${strapiId}`} className="text-center my-5">
            <Link
              to="/articles"
              className="py-4 px-5 bg-primary text-highlight border
                           hover:bg-highlight hover:text-primary
                           hover:border-primary hover:border-4"
            >
              Back to Articles
            </Link>
          </div>
        </div>
      );
    }

    return <div>Page not Found.</div>;
  };

  return <Layout>{renderArticle()}</Layout>;
};

export default ArticlesShow;
