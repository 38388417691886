import React from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./Codeblock";

const Markdown = ({ children }) => {
  const pSpacing = ({ node: { children: markdownChildren } }) => {
    const { value } = markdownChildren[0];

    return <p className="mb-5">{value}</p>;
  };

  return (
    <ReactMarkdown
      className="text-primary tracking-wide"
      renderers={{ code: CodeBlock, paragraph: pSpacing }}
      source={children}
    />
  );
};

export default Markdown;
