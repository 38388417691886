import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { xonokai } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = ({ language, value }) => (
  <div className="code-block my-5">
    <SyntaxHighlighter language={language} style={xonokai}>
      {value}
    </SyntaxHighlighter>
  </div>
);

export default CodeBlock;
