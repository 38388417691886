import React from "react";
import { graphql } from "gatsby";
import ArticlesShow from "../containers/Articles/Show";

const Article = ({ data }) => <ArticlesShow articleData={data?.article} />;

export const query = graphql`
  query GetSingleArticle($slug: String) {
    article: strapiArticles(slug: { eq: $slug }) {
      strapiId
      title
      content
      published_at(formatString: "Do MMMM YYYY")
      original_published_at(formatString: "Do MMMM YYYY")
    }
  }
`;

export default Article;
